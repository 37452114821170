<template>
	<header class="app-header">
		<a href="/" class="app-header__logo bg-orange p-0" :title="title">
			<img src="/assets/img/logo-panel.png">
		</a>
		<a
			class="app-sidebar__toggle"
			href="javascript:"
			data-toggle="sidebar"
			aria-label="Hide Sidebar"
			v-on:click="onToggleSideBar"
		></a>
		<ul class="app-nav">
			<li class="app-search">
				<input class="app-search__input" type="search" placeholder="Busca">
				<button class="app-search__button">
					<i class="fa fa-search"></i>
				</button>
			</li>
			<app-notifications user-id="userId"></app-notifications>
			<li class="dropdown">
				<a
					class="app-nav__item"
					href="javascript:" 
					title="Sair"
					v-on:click="onLogout"
				>
					<i class="fa fa-sign-out fa-lg"></i>
				</a>
			</li>
		</ul>
	</header>
</template>

<script>
	import Notification from './NotificationComponent';

	export default {
		components: {
			'app-notifications': Notification
		},
		props: {
			title: String
		},
		methods: {
			onLogout: () => {
				axios.post('/logout')
					.then(res => {
						window.location.href = '/';
					})
					.catch(err => {
						Flash('error', 'Não foi possível sair do sistema, tente novamente!');
					});
			},
			onToggleSideBar: () => {
				if (cash('.sidenav-toggled').length) {
					localStorage.setItem('menuState', 'show');
				} else {
					localStorage.setItem('menuState', 'hide');
				}
			}
		}
	}
</script>