/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import './bootstrap';
import './flash';
import './config';
import './mobile-check';
import './zoom';
import Vue from 'vue';
import AddScriptPlugin from './plugins/addScripts';
import Validator from './plugins/validator';

window.DEBUG = false;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

import Header from './components/HeaderComponent';
import Side from './components/SideComponent';
import Footer from './components/FooterComponent';
import Cover from './components/CoverComponent.vue';

/*	PAGES	*/
import Login from './components/LoginComponent.vue';

import Profile from './components/profiles/ProfileComponent.vue';
import ProfileEdit from './components/profiles/ProfileEditComponent.vue';

import Item from './components/items/ItemComponent.vue';
import ItemCreate from './components/items/ItemCreateComponent.vue';

import Background from './components/backgrounds/BackgroundComponent.vue';
import BackgroundCreate from './components/backgrounds/BackgroundCreateComponent.vue';
import BackgroundEdit from './components/backgrounds/BackgroundEditComponent.vue';

import Dashboard from './components/DashboardComponent.vue';

import Preview from './components/preview/PreviewComponent.vue';

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.use(AddScriptPlugin);
Vue.use(Validator);

const app = new Vue({
    el: '#app',
    components: {
    	'app-header': Header,
    	'app-side': Side,
    	'app-footer': Footer,
    	'app-cover': Cover,

    	'app-login': Login,

    	'app-profile': Profile,
    	'app-profile-edit': ProfileEdit,

        'app-item': Item,
        'app-item-create': ItemCreate,

        'app-background': Background,
        'app-background-create': BackgroundCreate,
        'app-background-edit': BackgroundEdit,

        'app-dashboard': Dashboard,

        'app-preview': Preview,
    }
});
