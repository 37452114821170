<template>
	<aside class="app-sidebar">
		<div class="app-sidebar__user">
			<img src="/assets/img/user-default.png" class="app-sidebar__user-avatar">
			<div class="col-8 p-0">
				<p class="app-sidebar__user-name">{{ user.name }}</p>
				<p class="app-sidebar__user-designation text-truncate">
					{{ user.profile.title }}
				</p>
			</div>
		</div>
		<app-menu></app-menu>
	</aside>
</template>

<script>
	import Menu from './MenuComponent';

	export default {
		components: {
			'app-menu': Menu
		},
		props: {
			user: Object,
		}
	}
</script>