<template>
	<div class="row">
		<app-jumbotron
			:user-name="userName"
			:date="date"
		></app-jumbotron>

        <div class="col-12 col-xl-3 col-md-6 mb-4">
            <div class="widget-small secondary coloured-icon mb-0">
                <i class="icon fa fa-archive"></i>
                <div class="info">
                    <h4>Itens ({{ items.total }})</h4>
                    <b>Face {{ items.face }} / Corpo {{ items.body }}</b>
                </div>
            </div>
            <div class="widget-small-footer bg-secondary">
                -
            </div>
        </div>
		<div class="col-12 col-xl-3 col-md-6 mb-4">
            <div class="widget-small success coloured-icon mb-0">
                <i class="icon fa fa-shopping-cart"></i>
                <div class="info">
                    <h4>Compras ({{ purchases.total }})</h4>
                    <b>Do sistema {{ purchases.system }} / De usuários {{ purchases.users }}</b>
                </div>
            </div>
            <div class="widget-small-footer bg-success">
                -
            </div>
        </div>
        <div class="col-12 col-xl-3 col-md-6 mb-4">
            <div class="widget-small warning coloured-icon mb-0">
                <i class="icon fa fa-money"></i>
                <div class="info">
                    <h4>Vendas ({{ sales.total }})</h4>
                    <b>
                    	Ativas {{ sales.active }} /
                    	Finalizadas {{ sales.finished }} /
                    	Canceladas {{ sales.canceled }}
                    </b>
                </div>
            </div>
            <div class="widget-small-footer bg-warning">
                -
            </div>
        </div>
        <div class="col-12 col-xl-3 col-md-6 mb-4">
            <div class="widget-small info coloured-icon mb-0">
                <i class="icon fa fa-exchange"></i>
                <div class="info">
                    <h4>Trocas ({{ exchanges.total }})</h4>
                    <b>
                    	Ativas {{ exchanges.active }} /
                    	Finalizadas {{ exchanges.finished }} /
                    	Canceladas {{ exchanges.canceled }}
                	</b>
                </div>
            </div>
            <div class="widget-small-footer bg-info">
                -
            </div>
        </div>
        <div class="col-12 col-xl-3 col-md-6 mb-4">
            <div class="widget-small info coloured-icon mb-0">
                <i class="icon fa fa-gavel"></i>
                <div class="info">
                    <h4>Leilões ({{ auctions.total }})</h4>
                    <b>
                    	Ativos {{ auctions.active }} /
                    	Arremates {{ auctions.finished }} /
                    	Finalizados {{ auctions.canceled }}
                    </b>
                </div>
            </div>
            <div class="widget-small-footer bg-info">
                -
            </div>
        </div>
        <div class="col-12 col-xl-3 col-md-6 mb-4">
            <div class="widget-small danger coloured-icon mb-0">
                <i class="icon fa fa-heart"></i>
                <div class="info">
                    <h4>Desejados ({{ wishes.total }})</h4>
                    <b>Ainda não tem {{ wishes.notObtained }} / Já tem {{ wishes.obtained }}</b>
                </div>
            </div>
            <div class="widget-small-footer bg-danger">
                -
            </div>
        </div>
	</div>
</template>

<script>
	import Jumbotron from './JumbotronComponent';

	export default {
		components: {
			'app-jumbotron': Jumbotron
		},
		data() {
			return {
                items: Object,
				purchases: Object,
				sales: Object,
				exchanges: Object,
				auctions: Object,
				wishes: Object
			};
		},
		props: {
			userName: String,
			date: String
		},
		mounted() {
			this.load();
		},
		methods: {
			load() {
				axios.get('/web/dashboard')
					.then(res => {
                        this.items = res.data.items;
						this.purchases = res.data.purchases;
						this.sales = res.data.sales;
						this.exchanges = res.data.exchanges;
						this.auctions = res.data.auctions;
						this.wishes = res.data.wishes;
					})
					.catch(err => {});
			}
		}
	}
</script>