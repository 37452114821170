var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('app-page-header',{attrs:{"icon":"fa fa-cog","title":"Perfis","breadcrumbs":[
			{ href: '/', title: 'Painel' }
		]}}),_vm._v(" "),_c('app-datatable',{attrs:{"entity":"profiles","options":{
			ajax: {
				url: '/web/profiles'
			},
			columns: this.columns
		},"actions":{
			show: {
				allow: this.allows.show,
				url: '*',
				callback: _vm.onShow
			},
			edit: {
				allow: this.allows.edit,
				url: '/profiles/*/edit',
			},
		}}}),_vm._v(" "),_c('app-profile-show',{ref:"profileShow"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }